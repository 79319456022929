import React from "react";
import { Link } from 'gatsby';
// import worksData from "data/sections/works.json";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
SwiperCore.use([Autoplay, Pagination, Navigation]);

const Works3 = () => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  return (
    <>
      <section className="work-carousel2 metro section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-10">
              <div className="sec-head2">
                <h6 className="wow fadeIn" data-wow-delay=".5s">
                  <span className="light-text">THE</span><span>REVBULL</span><span className="light-text"> DIFFERENCE</span>
                </h6>
                <h3 className="wow color-font">
                  Through proven targeted paid search strategies, we find high-intent prospects in real time, resulting in positives outcomes for insurance agents, while also providing a delightful consumer experience to insurance shoppers.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Works3;
